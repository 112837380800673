<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t('user_details.external_balance') }}</h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Dropdown-->
        <DatatableDropdown />
        <!--end::Dropdown-->
        <!--begin::Button-->
        <router-link 
          :to="`/users/${$route.params.id}/add-external-balance`"
          class="btn btn-outline-primary font-weight-bolder"
        >
          <i class="fa fa-plus"></i> {{ $t('user_details.add_external_balance') }}
        </router-link>
        <!--end::Button-->
      </div>
    </div>
    <div class="card-body">
      <div class="text-center" v-if="isLoading">{{ $t("commons.data_loading") }}...</div>
      <table class="table table-striped table-bordered collapsed" style="width:100%" ref="kt_datatable" v-else>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('user_details.provider') }}</th>
            <th scope="col">{{ $t('commons.currency') }}</th>
            <th scope="col">{{ $t('commons.quantity') }}</th>
            <th scope="col">{{ $t('user_details.exchange_rate') }} TRY</th>
            <th scope="col">{{ $t('user_details.exchange_rate') }} USD</th>
            <th scope="col">{{ $t('commons.amount') }} TRY</th>
            <th scope="col">{{ $t('commons.amount') }} USD</th>
            <th scope="col">{{ $t('finance.fee') }} TRY</th>
            <th scope="col">{{ $t('finance.fee') }} USD</th>
            <th scope="col">{{ $t("commons.operations") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in externalBalanceData" :key="idx">
            <th class="font-weight-bolder">{{ item.id }}</th>
            <td>{{ item.provider }}</td>
            <td>{{ item.currency_id }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.exchange_rate_try }}</td>
            <td>{{ item.exchange_rate_usd }}</td>
            <td>{{ item.amount_try }}</td>
            <td>{{ item.amount_usd }}</td>
            <td>{{ item.fee_try }}</td>
            <td>{{ item.fee_usd }}</td>
            <td>
              <router-link
                :to="`/users/${$route.params.id}/edit-external-balance/${item.id}`"
                v-if="checkPermission('crm.users.show.externalBalances.edit')"
                class="btn btn-sm btn-clean btn-icon btn-icon-md"
                :title="$t('commons.edit')"
              >
                <i class="la la-pencil"></i>
              </router-link>
              <button
                v-if="checkPermission('crm.users.show.externalBalances.destroy')"
                @click="deleteBalance(item.id)"
                type="button"
                class="btn btn-sm btn-clean btn-icon btn-icon-md"
                :title="$t('commons.delete')"
              >
                <i class="la la-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { mapState } from "vuex";
import DatatableDropdown from '@/components/datatable/Dropdown.vue'
import Swal from 'sweetalert2';

export default {
  name: "ExternalBalance",
  components: {
    DatatableDropdown
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      permissions: (state) => state.customer.permissions,
      isLoading: (state) => state.userdetails.isLoading,
      externalBalanceData: (state) => state.userdetails.externalBalanceData,
    }),
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(val => String(val.name) === String(permission));
    },
    deleteBalance(balance_id) {
      const vm = this;
      Swal.fire({
        title: this.$t("commons.are_you_sure"),
        text: this.$t("commons.wont_be_able_to_revert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("commons.yes_delete_it"),
        cancelButtonText: this.$t("commons.cancel"),
      }).then(function (result) {
        if (result.value) {
          vm.$store.dispatch('userdetails/externalBalancesDeleteAction', {
            user_id: vm.$route.params.id,
            balance_id
          })
        }
      });
    }
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.users.show.externalBalances")) {
      this.$router.push("/no-permission");
      return;
    }
    const self = this;
    self.$store.dispatch('userdetails/getExternalBalancesAction', { id: this.$route.params.id }).then(() => {
      self.$nextTick(function () {
        const fileName = "external-balances";
        $(self.$refs["kt_datatable"]).DataTable({
          responsive: true,
          dom: "Blfrtip",
          pagingType: 'full_numbers',
          buttons: ["copy", "csv", "excel", "pdf", "print"].map((el) => ({
            extend: el,
            filename: fileName,
          })),
          initComplete: function() {
            $('.dt-buttons').hide();
          },
          columnDefs: [
            { orderable: false, targets: -1 },
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 },
          ],
          order: [[0, "desc"]],
          pageLength: 10,
          lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "All"],
          ],
          language: {
            url: `/assets/languages/datatable/${this.lang}.json`,
          },
        });
        $("#printExport").on("click", function (e) {
          e.preventDefault();
          $(".buttons-print").trigger("click");
        });
        $("#copyExport").on("click", function (e) {
          e.preventDefault();
          $(".buttons-copy").trigger("click");
        });
        $("#excelExport").on("click", function (e) {
          e.preventDefault();
          $(".buttons-excel").trigger("click");
        });
        $("#csvExport").on("click", function (e) {
          e.preventDefault();
          $(".buttons-csv").trigger("click");
        });
        $("#pdfExport").on("click", function (e) {
          e.preventDefault();
          $(".buttons-pdf").trigger("click");
        });
      });
    })
  },
};
</script>

<style lang="scss">
</style>
